/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
'use strict';

window.ranger = window.ranger || {};

/**
 * Handles the Home and Feed UI.
 */
ranger.Feed = class {

  /**
   * Initializes the Friendly Pix feeds.
   * @constructor
   */
  constructor() {
    // List of all posts on the page.
    this.posts = [];
    // Map of posts that can be displayed.
    this.newPosts = {};

    // Firebase SDK.
    this.auth = firebase.auth();
    this.database = firebase.database();

    $(document).ready(() => {
      // Pointers to DOM elements.
      this.pageFeed = $('#page-feed');
      this.feedImageContainer = $('.fp-image-container', this.pageFeed);
      this.noPostsMessage = $('.fp-no-posts', this.pageFeed);
      this.nextPageButton = $('.fp-next-page-button button');
      this.newPostsButton = $('.fp-new-posts-button button');

      // Event bindings.
      this.newPostsButton.click(() => this.showNewPosts());

      $('#page-feed').on('click', '.fp-done-review', function() {
        ranger.firebase.removeFromFeed(firebase.auth().currentUser.uid, $(this).data('id'));
        $('.fp-post-'+$(this).data('id')).remove();
      });

      $('#page-feed').on('click', '.fp-copy-feed', function() {
        ranger.firebase.copyToPosts(firebase.auth().currentUser.uid, $(this).data('id'), $(this).data('title'));
      });

      $('#page-feed').on('click', '.fp-duplicatePostBtn', function() {
        ranger.firebase.duplicatePost($(this).data('id'), firebase.auth().currentUser.uid);
        ranger.feed.showNewPosts();
      });

      /*$('.sortCategoryList').on('click', 'li', function() {
        if($(this).data('id') === 'all') {
          ranger.feed.showHomeFeed();
        } else {
          ranger.feed.showCategoryFeed($(this).data('id'));
        }
      })

      $('body').on('click', '.fp-setCategory', function() {
        ranger.range.stopListeners();
        $('#categoriesForm').modal('show');
        window.postKey = $(this).data('id');
        ranger.range.startListeners();
      });*/
    });

  }

/** Load Sort **/
  fillSortCategories() {
    ranger.category.startCategoryListeners();
  /*let categories = this.database.ref('/people/'+this.auth.currentUser.uid + '/categories/').once('value', function(data) {
    $('.sortCategoryList').empty();
    $('.sortCategoryList').append(`<a data-id="all" class="dropdown-item">Show All</a>`);
    $('#sortCategories').show();
    $.each(data.val(), function(key, value) {
      var html = `<div class="btn-group dropdown-item" role="group"><a data-id="${key}" class="btn btn-warning btnCategorySort">${value}</a>
      <a data-id="${key}" class="btn btn-warning categoryDelete"><i class="material-icons">delete</a></div>`;
      $('.sortCategoryList').append(html);
    })
  })*/
  }

  /**
   * Appends the given list of `posts`.
   */
  addPosts(posts, general = false, review = false) {
    // Displays the list of posts
    const postIds = Object.keys(posts);
    for (let i = postIds.length - 1; i >= 0; i--) {
      this.noPostsMessage.hide();
      const postData = posts[postIds[i]];
    if (general === true && postData.public === false) {
    } else {

/*var defaultRange  = `<div class="fp-handhistory"><div><span class="flop-block"><div class="card mdl-color--blue"><span class="corner">A</span><span class="suit diamonds"></span><span class="middle">A</span></div></span><span class="flop-block"><div class="card mdl-color--green"><span class="corner">A</span><span class="suit clubs"></span><span class="middle">A</span></div></span><span class="flop-block"><div class="card mdl-color--red"><span class="corner">A</span><span class="suit hearts"></span><span class="middle">A</span></div></span><span class="flop-block"><div class="card mdl-color--grey"><span class="corner">A</span><span class="suit spades"></span><span class="middle">A</span></div></span><hr><div class="heroHolder"><div class="card mdl-color--orange"></div> <div class="card mdl-color--orange"></div><div class="starsBottom"><span>Range(s)</span><span></span></div></div><br></div></div>`;
*/
var defaultRange = '<p>Range(s)</p><img src="/images/Ranges.png" width="275px" height="272px"/>';

      const post = new ranger.Post();
      this.posts.push(post);
      const postElement = post.fillPostData(postIds[i], postData.thumb_url || postData.url,
          postData.text, postData.author, postData.timestamp, null, null, postData.full_url, postData.handPreview || defaultRange);
      // If a post with similar ID is already in the feed we replace it instead of appending.
      const existingPostElement = $(`.fp-post-${postIds[i]}`, this.feedImageContainer);
      if(this.auth.currentUser.uid != postData.author.uid && postData.public === false) {
          if(typeof postData.members[this.auth.currentUser.uid] != 'undefined') {
              if (existingPostElement.length) {
                existingPostElement.replaceWith(postElement);
              } else {
                this.feedImageContainer.append(postElement.addClass(`fp-post-${postIds[i]}`));
              }
          }
      } else {
          if (existingPostElement.length) {
            existingPostElement.replaceWith(postElement);
          } else {
            this.feedImageContainer.append(postElement.addClass(`fp-post-${postIds[i]}`));
          }
      }
      if (review === true) {
        $('.fp-review').show();
      }
    }
    }
  }

  /**
   * Shows the "load next page" button and binds it the `nextPage` callback. If `nextPage` is `null`
   * then the button is hidden.
   */
  toggleNextPageButton(nextPage, general = false) {
    this.nextPageButton.unbind('click');
    if (nextPage) {
      const loadMorePosts = () => {
        this.nextPageButton.prop('disabled', true);
        console.log('Loading next page of posts.');
        nextPage().then(data => {
          this.addPosts(data.entries, general);
          this.toggleNextPageButton(data.nextPage);
          $('.fp-duplicate').show();
        });
      };
      this.nextPageButton.show();
      // Enable infinite Scroll.
      ranger.MaterialUtils.onEndScroll(100).then(loadMorePosts);
      this.nextPageButton.prop('disabled', false);
      this.nextPageButton.click(loadMorePosts);
    } else {
      this.nextPageButton.hide();
    }
  }

  /**
   * Prepends the list of new posts stored in `this.newPosts`. This happens when the user clicks on
   * the "Show new posts" button.
   */
  showNewPosts() {
    const newPosts = this.newPosts;
    this.newPosts = {};
    this.newPostsButton.hide();
    const postKeys = Object.keys(newPosts);

    for (let i = 0; i < postKeys.length; i++) {
      this.noPostsMessage.hide();
      const post = newPosts[postKeys[i]];
      const postElement = new ranger.Post();
      this.posts.push(postElement);
      this.feedImageContainer.prepend(postElement.fillPostData(postKeys[i], post.thumb_url ||
          post.url, post.text, post.author, post.timestamp, null, null, post.full_url));
    }
  }

  /**
   * Displays the general posts feed.
   */
  showGeneralFeed() {
    // Clear previously displayed posts if any.
    this.clear();

    // Load initial batch of posts.
    ranger.firebase.getPosts().then(data => {
      // Listen for new posts.
      const latestPostId = Object.keys(data.entries)[Object.keys(data.entries).length - 1];
      ranger.firebase.subscribeToGeneralFeed(
          (postId, postValue) => this.addNewPost(postId, postValue), latestPostId);

      // Adds fetched posts and next page button if necessary.
      this.addPosts(data.entries, true);
      this.toggleNextPageButton(data.nextPage, true);
      $('.fp-duplicate').show();
    });

    // Listen for posts deletions.
    ranger.firebase.registerForPostsDeletion(postId => this.onPostDeleted(postId));
  }

  showReviewFeed() {
    this.clear();

    // Load initial batch of posts.
    ranger.firebase.getReviewPosts().then(data => {
      // Listen for new posts.
      const latestPostId = Object.keys(data.entries)[Object.keys(data.entries).length - 1];
      ranger.firebase.subscribeToReviewFeed(
          (postId, postValue) => this.addNewPost(postId, postValue), latestPostId);

      // Adds fetched posts and next page button if necessary.
      this.addPosts(data.entries, '', true);
      this.toggleNextPageButton(data.nextPage, true);
    });

    // Listen for posts deletions.
    ranger.firebase.registerForPostsDeletion(postId => this.onPostDeleted(postId));
  }

  showGroupFeed() {
      this.clear();

      //Load group posts
      ranger.firebase.getGroupPosts().then(data => {
          ranger.firebase.subscribeToGroupFeed(
              (postId, postValue) => this.addNewPost(postId, postValue), latestPostId, groupId);

          // Adds fetched posts and next page button if necessary.
          this.addPosts(data.entries);
          this.toggleNextPageButton(data.nextPage, true);
      });
  }

  /**
   * Shows the feed showing all followed users.
   */
  showHomeFeed() {
    // Clear previously displayed posts if any.
    this.clear();

    if (this.auth.currentUser) {
      // Make sure the home feed is updated with followed users's new posts.
      ranger.firebase.updateHomeFeeds().then(() => {
        // Load initial batch of posts.
        ranger.firebase.getHomeFeedPosts().then(data => {
          const postIds = Object.keys(data.entries);
          if (postIds.length === 0) {
            this.noPostsMessage.fadeIn();
          }
          // Listen for new posts.
          const latestPostId = postIds[postIds.length - 1];
          ranger.firebase.subscribeToHomeFeed(
              (postId, postValue) => {
                this.addNewPost(postId, postValue);
              }, latestPostId);
          // Adds fetched posts and next page button if necessary.
          this.addPosts(data.entries);
          this.toggleNextPageButton(data.nextPage);
          $('.fp-duplicate').show();
        });

        // Add new posts from followers live.
        ranger.firebase.startHomeFeedLiveUpdaters();
        ranger.category.startCategoryListeners();

        // Listen for posts deletions.
        ranger.firebase.registerForPostsDeletion(postId => this.onPostDeleted(postId));
      });
    }
  }

  showCategoryFeed(categoryId) {
    this.clear();

    // Load initial batch of posts.
    ranger.firebase.getCategoryPosts(categoryId).then(data => {
      // Listen for new posts.
      const latestPostId = Object.keys(data.entries)[Object.keys(data.entries).length - 1];
      ranger.firebase.subscribeToCategoryFeed(
          (postId, postValue) => this.addNewPost(postId, postValue), latestPostId, categoryId);

      // Adds fetched posts and next page button if necessary.
      this.addPosts(data.entries);
      this.toggleNextPageButton(data.nextPage, true);
      $('.fp-duplicate').show();
    });

    // Listen for posts deletions.
    ranger.firebase.registerForPostsDeletion(postId => this.onPostDeleted(postId));
  }

  /**
   * Triggered when a post has been deleted.
   */
  onPostDeleted(postId) {
    // Potentially remove post from in-memory new post list.
    if (this.newPosts[postId]) {
      delete this.newPosts[postId];
      const nbNewPosts = Object.keys(this.newPosts).length;
      this.newPostsButton.text(`Display ${nbNewPosts} new posts`);
      if (nbNewPosts === 0) {
        this.newPostsButton.hide();
      }
    }
    // Potentially delete from the UI.
    $(`.fp-post-${postId}`).remove();
    ranger.MaterialUtils.updateBadges();

  }

  /**
   * Adds a new post to display in the queue.
   */
  addNewPost(postId, postValue) {
    this.newPosts[postId] = postValue;
    this.newPostsButton.text(`Display ${Object.keys(this.newPosts).length} new posts`);
    this.newPostsButton.show();
    ranger.MaterialUtils.updateBadges();
  }

  /**
   * Clears the UI.
   */
  clear() {
    // Delete the existing posts if any.
    $('.fp-post', this.feedImageContainer).remove();

    // Hides the "next page" and "new posts" buttons.
    this.nextPageButton.hide();
    this.newPostsButton.hide();

    // Remove any click listener on the next page button.
    this.nextPageButton.unbind('click');

    // Stops then infinite scrolling listeners.
    ranger.MaterialUtils.stopOnEndScrolls();

    // Clears the list of upcoming posts to display.
    this.newPosts = {};

    // Displays the help message for empty feeds.
    this.noPostsMessage.hide();

    // Remove Firebase listeners.
    ranger.firebase.cancelAllSubscriptions();

    // Stops all timers if any.
    this.posts.forEach(post => post.clear());
    this.posts = [];
  }
};

ranger.feed = new ranger.Feed();
